/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql, Link } from 'gatsby';

import MenueIcon from '../icons/MenuArrow';

const MainHeader = ({ setShowModal }) => {
  const data = useStaticQuery(graphql`
  {
    contentfulHeader {
      logo {
        url
        logoImage {
          gatsbyImageData(width: 320, placeholder: BLURRED)
          filename
        }
      }
      navigation {
        name
        url
      }
      signIn {
        name
      }
    }
  }
`);
  const navListData = data.contentfulHeader.navigation;
  const hightechLogo = data.contentfulHeader.logo;

  const [active, setActive] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="container max-w-[100%]">
      <div className="flex items-center justify-between h-16 md:h-20">
        <Link to={hightechLogo.url} rel="preload" className="max-width-150px md:max-width-full cursor-pointer flex items-center flex-shrink-0 mr-6 w-[120px]">
          <GatsbyImage image={hightechLogo.logoImage.gatsbyImageData} alt={hightechLogo.logoImage.filename} className="md:w-auto w-[70px]" />
        </Link>
        <ul className="items-center hidden space-x-8 lg:flex bg:white">
          {navListData.map(({ name, url }, index) => (
            <li key={index}>
              <Link
                to={url}
                aria-label={name}
                title={name}
                className={`active cursor-pointer font-light tracking-wide text-white
                    transition-colors duration-200 hover:text-primary ${
                    active ? 'text-primary' : 'text-white'
                  }`}
                onClick={() => setActive(active)}
              >
                {name}
              </Link>
            </li>
          ))}
          <div>
            <button
              type="button"
              onClick={() => setShowModal(true)}
              className="flex items-center justify-center px-4 py-2 border border-primary transition rounded-md shadow-sm text-sm-1 font-medium text-primary hover:bg-primary hover:text-white"
            >
              {data.contentfulHeader.signIn.name}
            </button>
          </div>
        </ul>
        <div className="lg:hidden">
          <button
            aria-label="Open Menu"
            title="Open Menu"
            className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
            onClick={() => setIsMenuOpen(true)}
          >
            <MenueIcon />
          </button>
          {isMenuOpen && (
            <div className="absolute top-0 left-0 w-full z-50">
              <div className="p-5 bg-background border rounded shadow-sm">
                <div className="flex items-center justify-between mb-4">
                  <Link to="/" rel="preload" className="flex items-center flex-shrink-0 mr-6">
                    <GatsbyImage className="cursor-pointer w-32" image={hightechLogo.logoImage.gatsbyImageData} alt="hightech logo" />
                  </Link>
                  <div>
                    <button
                      aria-label="Close Menu"
                      title="Close Menu"
                      className="cursor-pointer p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <nav className="z-50">
                  <ul>
                    {navListData.map(({ name, url }, index) => (
                      <li key={index}>
                        <Link
                          to={url}
                          aria-label={name}
                          title={name}
                          onClick={() => setIsMenuOpen(false)}
                          className="relative flex border-t-0.6 border-solid border-muted min-h-[75px] items-center cursor-pointer font-medium tracking-wide text-white transition-colors duration-200 hover:text-primary"
                        >
                          {name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
